import React from 'react';
import ShowLazyPanel from '@sistemas.mundo/show-lazy-panel';
import {Route, BrowserRouter as Router} from 'react-router-dom';
import { matchPath } from "react-router";

const RouteSwitchLazyPanel = props => {

  const {
      onShow
      ,color
      ,staticContext
      ,itemsPanels
      ,forceHide
      ,defaultPath
      , ...rest
  } = props

  const [value, setValue] = React.useState(null);
  const [basename, setBasename] = React.useState(null);

  React.useEffect(() => {
    if (props.basename){
      setBasename(props.basename === '/' ? '' : props.basename);
    }
  }, [props.basename]);

  React.useEffect(() => {

    if  (basename === null) return;

    const matchBasePath = matchPath(props.location.pathname, {
      path: `${props.basename}/`
      ,exact: true
      ,strict: false
    });

    if (matchBasePath){
      if(props.itemsPanels.filter((panel) => panel.path === `/` && panel.hidden !== true && panel.disabled !== true).length){
        setValue(`/`);
        return;
      }
    }

    const matchBase = matchPath(props.location.pathname, {
      path: `${props.basename}`
      ,exact: false
      ,strict: false
    });

    if (matchBase?.isExact === true){
      if (!value && props.defaultPath){
        if(props.itemsPanels.filter((panel) => panel.path === props.defaultPath  && panel.hidden !== true && panel.disabled !== true).length){
          setValue(`${props.defaultPath}`);
          props.history.push(`${basename}${props.defaultPath}`)
        }
      }
    }
    else if(matchBase){
      const matchRoot = matchPath(props.location.pathname, {
        path: `${basename}/:path`
        ,exact: false
        ,strict: false
      });
      if (matchRoot){
        if (matchRoot.params.path){
          if(props.itemsPanels.filter((panel) => panel.path === `/${matchRoot.params.path}` && panel.hidden !== true && panel.disabled !== true).length){
            setValue(`/${matchRoot.params.path}`);
            //props.history.push(`${basename}/${matchRoot.params.path}`)
          }
        }
      }
    }

  })

  React.useEffect(() => {
    if (value){
      const item = props.itemsPanels.filter((panel) => panel.path === value)
      if(item.length){
        if (item[0].title ){
          document.title = item[0].title
        }
        else if (item[0].label ){
          document.title = item[0].label
        }
      }
    }
  },[value])

  const getPanelShow = (panel, panelProps, indice) => {
    if (forceHide === true) {
      return (
        <ShowLazyPanel
            {...rest}
            {...panelProps}
            show={value === panel.path && panel.hidden !== true && panel.disabled !== true}
            key={`slp-${panel.path}-${indice}`}
            basename={`${basename}${panel.path}`}
        />
      )
    }
    else{
      return (
        <Route
            key={`rte-${panel.path}-${indice}`}
            path={`${panel.path}`}
            exact={false}
            render={(matchProps) => {
                return (
                  <ShowLazyPanel
                      {...rest}
                      {...panelProps}
                      show={value === panel.path && panel.hidden !== true && panel.disabled !== true}
                      key={`slp-${panel.path}-${indice}`}
                      basename={`${basename}${panel.path}`}
                  />
                )
            }}
        />
      )
    }
  }

  return (
    <Router basename={props.basename} exact={false}>
        {props.itemsPanels.map((panel, indice) => {
            const {
                icon
                ,path
                ,label
                ,tooltip
                ,itemsPanels
                ,defaultPath
                , ...panelProps} = panel
            return getPanelShow(panel, panelProps, indice)
        })}
    </Router>
  );
}

RouteSwitchLazyPanel.defaultProps = {
    itemsPanels: []
    ,defaultPath: null
    ,basename: '/'
    ,color: "primary"
    ,onShow: (newpath) => true
    ,forceHide: true
}

export default RouteSwitchLazyPanel;
